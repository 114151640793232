  import React, { useState, useContext, useEffect } from "react";
  import { Context } from "../contexts/store";
  import { api } from "../services/api";
  import { useNavigate } from "react-router-dom";
  import TextField from "@mui/material/TextField";
  import IconButton from "@mui/material/IconButton";
  import List from "@mui/material/List";
  import ListItem from "@mui/material/ListItem";
  import ListItemText from "@mui/material/ListItemText";
  import Grid from "@mui/material/Grid";
  import SortIcon from "@mui/icons-material/Sort";
  import EditUserModal from "../components/EditUserModal";

  interface User {
    prenom: string;
    nom: string;
    tel: string;
    email: string;
    _id: string;
    tokens: number;
  }

  const Users = () => {
    const navigate = useNavigate();
    const { token, admin } = useContext(Context);
    const [users, setUsers] = useState<User[]>([]);
    const [search, setSearch] = useState("");
    const [sortType, setSortType] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const fetchUsers = async () => {
      api.get<User[]>('/users', token).then(res => {
        setUsers(res.data);
      })
    }

    useEffect(() => {

      if (!token)
        navigate('/');
      if (!admin)
        navigate('/cours');
      fetchUsers();

    }, [admin, token]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    };

    const handleSort = () => {
      setSortType(!sortType);
    };

    let filteredUsers = users.filter((user: User) => {
      let searchArray = search.toLowerCase().split(' ');
      return searchArray.every(item => user.prenom.toLowerCase().includes(item) || user.nom.toLowerCase().includes(item));
    });
    

    if (sortType) {
      filteredUsers.sort((a: User, b: User) => (a.prenom > b.prenom) ? 1 : -1);
    }

    const selectUser = (user: User) => {
      console.log("user = ", user);
      setSelectedUser(user);
      setOpenEditModal(true);
    }

    return (
      <Grid container direction="column" alignContent="center" justifyContent="center" alignItems="center" className="mt-4">
        <Grid item xs={12}>
          <TextField label="Rechercher" value={search} onChange={handleSearch} />
          <IconButton onClick={handleSort}>
            <SortIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <List>
            {filteredUsers.map((user: User, index: number) => {
              return (
                <ListItem key={index}>
                  <ListItemText primary={`${user.prenom} ${user.nom}`} secondary={`Tel: ${user.tel} | Email: ${user.email}`} />
                  <span className={"font-bold mx-1 ml-1 sm:ml-4"}>{user.tokens}</span>
                  <span className="material-symbols-outlined cursor-pointer ml-1" onClick={() => {selectUser(user)}}>
                     edit
                  </span>
                </ListItem>
              )
            })}
          </List>
        </Grid>
        {selectedUser !== null && <EditUserModal isOpen={openEditModal} setIsOpen={setOpenEditModal} selectedId={selectedUser._id} nbTokens={selectedUser.tokens || 0} onSuccess={fetchUsers} />}
      </Grid>
    );
  };

  export default Users;
