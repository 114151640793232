import {Fragment, useContext, useState, useEffect} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import type {Offer} from '../shared/interfaces'
import {OFFERS} from '../shared/interfaces'
import {api} from "../services/api";
import {Context} from '../contexts/store'

const EditUserModal = ({ isOpen, setIsOpen, selectedId, nbTokens, onSuccess}: {isOpen: any, setIsOpen: any, selectedId: string, nbTokens: number, onSuccess: any}) => {
    const [newTokens, setNewTokens] = useState(nbTokens);
    const {token, setTokens} = useContext(Context);
    const [error, setError] = useState("");

    useEffect(() => {
        setNewTokens(nbTokens);
    }, [nbTokens])

    const onClose = () => {
        setIsOpen(false);
    }

    const increaseTokens = () => {
        if (newTokens < 9999) {
            setNewTokens(prev => prev + 1);
        }
    }

    const decreaseTokens = () => {
        if (newTokens > 0) {
            setNewTokens(prev => prev - 1);
        }
    }

    const validateTokens = async () => {
        api.post(`/users/updateTokens/${selectedId}`, token, {
            numberTokens: newTokens
        }).then(res => {
            setIsOpen(false);
            onSuccess();
        }).catch(err => {
            setError("Une erreur est survenue");
        })
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative h-full z-[990]" onClose={onClose}>
                <div className="fixed inset-0 bg-black bg-opacity-25" />
                <div className="fixed inset-0 overflow-y-auto h-full">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Dialog.Panel className="h-[200px] w-[95%] lg:w-[35%] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col justify-between">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Nouveau nombre de tokens
                            </Dialog.Title>
                            <div className="flex items-center justify-center">
                                <button onClick={decreaseTokens} className={"text-2xl"}>-</button>
                                <input type="number" value={newTokens} min="0" max="9999" onChange={(e) => setNewTokens(Math.max(0, Math.min(9999, +e.target.value)))} className="mx-2 flex justify-center items-center text-center" />
                                <button onClick={increaseTokens} className={"text-2xl"}>+</button>
                            </div>
                            {error !== '' && <span className={"w-full text-red-500 text-lg text-center"}>{error}</span>}
                            <div className="flex justify-between">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-grey-100 px-4 py-2 text-sm font-medium text-[f46ef6] hover:bg-[#f4b8f5] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={() => { setIsOpen(false) }}
                                >
                                    Fermer
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-[#f46ef6] px-4 py-2 text-sm font-medium text-white hover:bg-[#f4b8f5] focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={validateTokens}
                                >
                                    Valider
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default EditUserModal;